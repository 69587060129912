export default {
  data() {
    return {
      loading: false,
      sort: null,
      order: null,
      search: null,
      searchTimer: null,
    }
  },
  created() {
    this.sort = this.$route.query?.sort;
    this.order = this.$route.query?.order !== 'desc' ? 1 : -1;
    this.search = this.$route.query?.search;
  },

  methods: {
    onPage(event) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: event.page + 1,
          per_page: event.rows,
        }
      });
    },

    onSort(event) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          sort: event.sortField,
          order: event.sortOrder === 1 ? 'asc' : 'desc',
        }
      });
    },

    onFilter(event, fields = []) {
      let query = Object.assign({}, this.$route.query);

      for (const field of fields) {
        delete query[field];

        if (event.filters[field]?.value) {
          query[field] = event.filters[field].value?.id ?? event.filters[field].value
          query.page = 1;
        }
      }

      this.$router.push({
        name: this.$route.name,
        query
      });
    },

    onSearch() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        let query = Object.assign({}, this.$route.query);
        delete query.search;

        if (this.search) {
          query.search = this.search;
          query.page = 1;
        }

        this.$router.push({
          name: this.$route.name,
          query
        });
      }, 1000);
    },
  },
}
