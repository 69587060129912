<template>
  <div>
    <Dialog v-model:visible="dialog" :style="{width: '450px'}" header="Edit" :modal="true" class="p-fluid" @hide="closeDialog">
      <!-- First name -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="firstName" v-model="v$.form.firstName.$model" :class="{'p-invalid':v$.form.firstName.$invalid && submitted}"/>
          <label for="firstName" :class="{'p-error':v$.form.firstName.$invalid && submitted}">First Name*</label>
        </div>

        <small
          v-if="(v$.form.firstName.$invalid && submitted) || v$.form.firstName.$pending.$response"
          class="p-error">{{ v$.form.firstName.required.$message.replace('Value', 'First Name') }}
        </small>
      </div>

      <!-- Last name -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="lastName" v-model="v$.form.lastName.$model" :class="{'p-invalid':v$.form.lastName.$invalid && submitted}"/>
          <label for="lastName" :class="{'p-error':v$.form.lastName.$invalid && submitted}">Last Name*</label>
        </div>

        <small
          v-if="(v$.form.lastName.$invalid && submitted) || v$.form.lastName.$pending.$response"
          class="p-error">{{ v$.form.lastName.required.$message.replace('Value', 'Last Name') }}
        </small>
      </div>

      <!-- Email -->
      <div class="field">
        <div class="p-float-label mb-5 p-input-icon-right">
          <i class="pi pi-envelope"/>
          <InputText id="email" v-model="v$.form.email.$model" :class="{'p-invalid':v$.form.email.$invalid && submitted}" aria-describedby="email-error"/>
          <label for="email" :class="{'p-error':v$.form.email.$invalid && submitted}">Email*</label>
        </div>

        <span v-if="v$.form.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.form.email.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
              </span>
            </span>
        <small
          v-else-if="(v$.form.email.$invalid && submitted) || v$.form.email.$pending.$response"
          class="p-error">{{ v$.form.email.required.$message.replace('Value', 'Email') }}
        </small>
      </div>

      <!-- Phone -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="phone" v-model="v$.form.phone.$model" :class="{'p-invalid':v$.form.phone.$invalid && submitted}"/>
          <label for="phone" :class="{'p-error':v$.form.phone.$invalid && submitted}">Phone*</label>
        </div>

        <small
          v-if="(v$.form.phone.$invalid && submitted) || v$.form.phone.$pending.$response"
          class="p-error">{{ v$.form.phone.required.$message.replace('Value', 'Phone') }}
        </small>
      </div>

      <!-- Language -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="language" v-model="v$.form.language.$model" :class="{'p-invalid':v$.form.language.$invalid && submitted}"/>
          <label for="language" :class="{'p-error':v$.form.language.$invalid && submitted}">Language*</label>
        </div>

        <small
          v-if="(v$.form.language.$invalid && submitted) || v$.form.language.$pending.$response"
          class="p-error">{{ v$.form.language.required.$message.replace('Value', 'Language') }}
        </small>
      </div>

      <!-- Business type -->
      <div class="field">
        <div class="p-float-label mb-5">
          <Dropdown id="businessType" :options="businessTypes" optionLabel="name" v-model="selectedBusinessType" />
          <label for="businessType">Type of business*</label>
        </div>

        <small
          v-if="(v$.selectedBusinessType.$invalid && submitted)"
          class="p-error">{{ v$.selectedBusinessType.required.$message.replace('Value', 'Type of business') }}
        </small>
      </div>

      <!-- Business address -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="businessAddress" v-model="v$.form.businessAddress.$model" :class="{'p-invalid':v$.form.businessAddress.$invalid && submitted}" />
          <label for="businessAddress" :class="{'p-error':v$.form.businessAddress.$invalid && submitted}">Business Address*</label>
        </div>

        <small
          v-if="(v$.form.businessAddress.$invalid && submitted) || v$.form.businessAddress.$pending.$response"
          class="p-error">{{ v$.form.businessAddress.required.$message.replace('Value', 'Business Address') }}
        </small>
      </div>

      <!-- Business name -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="businessName" v-model="v$.form.businessName.$model" :class="{'p-invalid':v$.form.businessName.$invalid && submitted}" />
          <label for="businessName" :class="{'p-error':v$.form.businessName.$invalid && submitted}">Business Name*</label>
        </div>

        <small
          v-if="(v$.form.businessName.$invalid && submitted) || v$.form.businessName.$pending.$response"
          class="p-error">{{ v$.form.businessName.required.$message.replace('Value', 'Business Name') }}
        </small>
      </div>

      <!-- Facebook url -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="facebookUrl" v-model="v$.form.facebookUrl.$model" :class="{'p-invalid':v$.form.facebookUrl.$invalid && submitted}" />
          <label for="facebookUrl" :class="{'p-error':v$.form.facebookUrl.$invalid && submitted}">Facebook url</label>
        </div>

        <small
          v-if="(v$.form.facebookUrl.$invalid && submitted) || v$.form.facebookUrl.$pending.$response"
          class="p-error">{{ v$.form.facebookUrl.url.$message.replace('Value', 'Business Name') }}
        </small>
      </div>

      <!-- Instagram url -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="instagramUrl" v-model="v$.form.instagramUrl.$model" :class="{'p-invalid':v$.form.instagramUrl.$invalid && submitted}" />
          <label for="instagramUrl" :class="{'p-error':v$.form.instagramUrl.$invalid && submitted}">Instagram url</label>
        </div>

        <small
          v-if="(v$.form.instagramUrl.$invalid && submitted) || v$.form.instagramUrl.$pending.$response"
          class="p-error">{{ v$.form.instagramUrl.url.$message.replace('Value', 'Instagram url') }}
        </small>
      </div>

      <!-- Linkedin url -->
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="linkedinUrl" v-model="v$.form.linkedinUrl.$model" :class="{'p-invalid':v$.form.linkedinUrl.$invalid && submitted}" />
          <label for="linkedinUrl" :class="{'p-error':v$.form.linkedinUrl.$invalid && submitted}">Linkedin url</label>
        </div>

        <small
          v-if="(v$.form.linkedinUrl.$invalid && submitted) || v$.form.linkedinUrl.$pending.$response"
          class="p-error">{{ v$.form.linkedinUrl.url.$message.replace('Value', 'Linkedin url') }}
        </small>
      </div>

      <!-- Notes -->
      <div class="field">
        <div class="p-float-label mb-5">
          <Textarea id="notes" v-model="form.notes" rows="5" cols="30" />
          <label for="notes">Notes</label>
        </div>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="dialog = false" />
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveUser(!v$.$invalid)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {email, required, url} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import companyResource from "@http/CompanyResource";
import company from "@models/Company";

export default {
  setup: () => ({v$: useVuelidate()}),
  emits: ["hideDialog"],

  created() {
    if (this.$route.query?.company) {
      this.loadEdit(this.$route.query?.company);
    }
  },

  data() {
    return {
      dialog: false,
      selectedBusinessType: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        language: '',
        businessAddress: '',
        businessName: '',
        businessType: '',
        facebookUrl: '',
        instagramUrl: '',
        linkedinUrl: '',
      },
      filters: {},
      submitted: false,
    }
  },

  methods: {
    openNew() {
      this.form = {};
      this.submitted = false;
      this.dialog = true;
    },

    saveUser(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.form.businessType = this.selectedBusinessType.id;

      companyResource.update(this.$route.query?.company, this.form)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
          this.loading = true;
          this.closeDialog();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    loadEdit(id) {
      companyResource.company(id)
        .then(({data}) => {
          this.form.firstName = data.first_name;
          this.form.lastName = data.last_name;
          this.form.email = data.email;
          this.form.phone = data.phone;
          this.form.language = data.language;
          this.form.businessAddress = data.business_address;
          this.form.businessName = data.business_name;
          this.form.businessType = data.business_type;
          this.form.facebookUrl = data.facebook_url;
          this.form.instagramUrl = data.instagram_url;
          this.form.linkedinUrl = data.linkedin_url;
          this.selectedBusinessType = this.businessTypes.find(el => el.id === data.business_type);
          this.submitted = false;
          this.dialog = true;
        });
    },

    closeDialog() {
      this.submitted = false;
      this.dialog = false;
      this.form = {};
      let query = Object.assign({}, this.$route.query);
      delete query.company;
      this.$router.push({
        name: this.$route.name,
        query
      });
    },
  },

  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.company) {
          this.loadEdit(query.company);
        }
      }
    },
  },

  computed: {
    businessTypes() {
      return company.typesOfBusiness;
    }
  },

  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: { required },
        language: { required },
        businessAddress: { required },
        businessName: { required },
        facebookUrl: { url },
        instagramUrl: { url },
        linkedinUrl: { url },
      },
      selectedBusinessType: { required },
    };
  },
}
</script>
